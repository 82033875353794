import { css } from '@emotion/react';
import { colors } from '@packages/ui';

export default css`
  html[dir='rtl'] {
    * {
      text-align: inherit;
      font-family: Nunito, system-ui, BlinkMacSystemFont, 'Segoe UI', Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  /* Scrollbar */

  // Firefox
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  // Webkit-based browsers (Chrome, Edge, Safari)
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0.9rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: padding-box;

    &:hover {
      background-color: rgba(0, 0, 0, 0.25);
      border: 3px solid transparent;
      background-clip: padding-box;
    }
  }

  body {
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0;
    background-color: ${colors.background};
    position: relative;
    min-height: 100vh;
  }

  a {
    text-decoration: none;
  }

  @media print {
    body {
      background-color: var(--white);
    }
  }

  div.flatpickr-calendar {
    box-shadow: var(--elevation-200);
    &:before,
    &:after {
      display: none;
    }

    .flatpickr-day.selected {
      border-color: var(--primary);
      background-color: var(--primary);
    }

    .flatpickr-day.today:not(.selected) {
      border-bottom-color: var(--danger-500);
    }

    .flatpickr-day.today:hover,
    .flatpickr-day.today:focus,
    .flatpickr-day.today:not(.selected):hover {
      border-color: var(--gray-300);
      border-bottom-color: var(--danger-500);
      background-color: var(--gray-300);
      color: inherit;
    }
  }

  .withRtl {
    text-align: inherit !important;
  }

  .content-wrap {
    min-height: calc(100vh - 32px);
    overflow: hidden;
  }
`;
